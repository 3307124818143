import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ApiService {
  @Inject(HttpService) private http!: HttpService;

  public getList(): Promise<any> {
    return this.http.get('/api/apis');
  }
}
