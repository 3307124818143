import { EditorStatus } from '../index.interface';

export const getRules = (type?: EditorStatus) => {
  const rules: any = [
    {
      name: [{ required: true, message: '请输入平台协议', trigger: 'blur' }]
      // departmentCode: [
      //   {
      //     type: 'array',
      //     required: true,
      //     message: '请至少选择一个',
      //     trigger: 'change'
      //   }
      // ]
      // disclaimer: [
      //   { required: true, message: '请输入免责声明', trigger: 'blur' }
      // ],
      // cancelReminder: [
      //   { required: true, message: '请输入撤销提醒', trigger: 'blur' }
      // ]
    },
    {
      content: [
        {
          required: true,
          message: '发布隐私协议请输入协议描述',
          trigger: 'blur'
        }
      ]
    },
    {}
    // {
    //   departmentCode: [
    //     {
    //       type: 'array',
    //       required: true,
    //       message: '请至少选择一个',
    //       trigger: 'change'
    //     }
    //   ],
    //   // disclaimer: [
    //   //   { required: true, message: '请输入免责声明', trigger: 'blur' }
    //   // ],
    //   cancelReminder: [
    //     { required: true, message: '请输入撤销提醒', trigger: 'blur' }
    //   ]
    // }
  ];
  switch (type) {
    case EditorStatus.save_as:
      rules[rules.length - 1].effectiveTime = [
        {
          required: true,
          message: '发布隐私协议请输入选择生效日期',
          trigger: 'blur'
        }
      ];
      rules[rules.length - 1].content = [
        {
          required: true,
          message: '发布隐私协议请输入协议描述',
          trigger: 'blur'
        }
      ];
      break;
  }
  return rules;
};

export const stepList = ['基本信息', '同意描述', '免责声明', '撤销提醒'];

export const requiredList = [
  { value: 1, label: '必填' },
  { value: 0, label: '非必填' }
];
