import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ManagerService {
  @Inject(HttpService) private http!: HttpService;

  public getList(param: Dict<any>): Promise<any> {
    let params = {
      fullName: param.fullName,
      roleId: param.roleId,
      email: param.email,
      employeeId: param.employeeId
    };
    return this.http.get(
      '/api/admin/page/' + param.offset + '/' + param.limit,
      params
    );
  }

  public getProfileAuthorityList(param: Dict<any>): Promise<any> {
    return this.http.get('/api/profile/authority/list');
  }

  public getProfileAppAuthorityList(param?: Dict<any>): Promise<any> {
    return this.http.get('/api/profile/app/authortity/list');
  }

  public getProfilList(param?: Dict<any>): Promise<any> {
    return this.http.get('/api/profile');
  }

  public getProfileByid(id: number): Promise<any> {
    return this.http.get(`/api/profile/${id}`);
  }

  // public putProfile(params: Dict<any>): Promise<any> {
  //   const { profileId } = params;
  //   return this.http.put(`/api/profile/${profileId}`, params);
  // }
  public putProfile(params: Dict<any>): Promise<any> {
    const { profileId } = params;
    return this.http.post(`/api/profile/${profileId}`, params);
  }

  public deleteProfile(id: number): Promise<any> {
    return this.http.delete(`/api/profile/${id}`);
  }

  public postProfile(param: Dict<any>): Promise<any> {
    return this.http.post('/api/profile', param);
  }

  public edit(param: Dict<any>): Promise<any> {
    return this.http.put('/api/admin', param);
  }

  public simulate(userId: any): Promise<any> {
    return this.http.put('/api/user/simulate/' + userId);
  }

  public exportExcel(param: Dict<any>): Promise<any> {
    let params = {
      fullName: param.fullName,
      // roleId:param.roleId,
      email: param.email,
      employeeId: param.employeeId
    };
    return this.http.downloadFile(
      '/api/admin/export',
      params,
      'Admin Export Doc.xlsx'
    );
  }

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/api/admin', param);
  }

  public getRoles(): Promise<any> {
    return this.http.get('/api/role');
  }

  public delete(param: Dict<any>): Promise<any> {
    // console.log(':::service --::', param);
    return this.http.delete('/api/admin/' + param);
  }
  public getUserInfo(param: Dict<any>): Promise<any> {
    return this.http.get('/api/admin/' + param);
  }

  // 用户管理里面的division数据
  public getDepartmentdivision(): Promise<any> {
    return this.http.get('/api/hr/division/check');
  }
}
