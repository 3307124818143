import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ConsentService {
  @Inject(HttpService) private http!: HttpService;

  public getList(param: Dict<any>): Promise<any> {
    return this.http.get(`/api/consent/page`, param);
  }

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/api/consent', param);
  }

  public editConsent(param: Dict<any>): Promise<any> {
    return this.http.put('/api/consent', param);
  }

  public delete(id: string): Promise<any> {
    return this.http.delete(`/api/consent/${id}`);
  }

  public getplatform(division: string): Promise<any> {
    return this.http.get(`/api/consent/platform/check`, { division });
  }

  public getpublishedlist(): Promise<any> {
    return this.http.get(`/api/consent/platform/publishedlist`);
  }
}
