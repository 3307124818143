export function getRules(i18n: Dict<any>) {
  return {
    channelName: [
      {
        required: true,
        message:
          i18n.form_message.pleace_enter + i18n.form_message.channel_name,
        trigger: ['blur', 'change']
      },
      {
        min: 0,
        max: 50,
        message: i18n.form_message.length_0_50,
        trigger: ['blur', 'change']
      }
    ],
    channelAgentId: [
      {
        required: true,
        message: i18n.form_message.pleace_enter + i18n.form_message.agent_id,
        trigger: ['blur', 'change']
      },
      { validator(rule: any, value: any, callback: any) {
        (/^[0-9]*$/).test(value) ? callback() : callback(new Error(i18n.form_message.just_number));
      }
        , trigger: 'blur' }
      // {
      //   min: 3,
      //   max: 50,
      //   message: i18n.form_message.length_3_50,
      //   trigger: ['blur', 'change']
      // },
      // { type: 'number',
      //   message: i18n.form_message.just_number
      // }
    ],
    channelSecret: [
      {
        required: true,
        message:
          i18n.form_message.pleace_enter + i18n.form_message.channelSecret,
        trigger: 'change'
      }
    ],
    divisionCode: [
      {
        required: true,
        message: i18n.form_message.pleace_select + i18n.form_message.divison,
        trigger: 'change',
        type: 'array'
      }
    ],
    apiIds: [
      {
        required: true,
        message: i18n.form_message.pleace_select + i18n.form_message.apiIds,
        trigger: ['blur', 'change']
      }
    ],
    thirdParty: [
      {
        required: true,
        message: i18n.form_message.pleace_select + i18n.form_message.jump_type,
        trigger: ['blur', 'change']
      }
    ]
  };
}

export const DefaultFormData = {
  id: '',
  channelName: '',
  channelAgentId: '',
  channelSecret: '',
  agentId: '',
  secret: '',
  divisionCode: [] as any,
  multipleSelection: [], // 表格多选
  apiIds: [],
  actionType: '',
  divisionList: [] as any[],
  formData: '',
  thirdPartyTitle: '',
  thirdPartyDescription: '',
  mfa:'',
  mpAppid:'',
  mpHome:''
};

export const DivisionOption = [
  { label: localStorage.getItem("setup"), value: 1 },
  { label: 'HR Core', value: 3},
  { label: 'NON FF', value:6}
];

export const DefaultFormTags = {
  id: '',
  applicationName: '',
  channelName: '',
  tagIds: [],
  // departmentNames:'',
  departmentIds: [],
  reportLineLv1Id: '',
  reportLineLv1Name: '',
  reportLineLv2Id: '',
  reportLineLv2Name: '',
  reportLineLv3Id: '',
  reportLineLv3Name: '',
  actionType: ''
};

export const DefaultProps = {
  children: 'children',
  label: 'label'
};
