


































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';

@Component({})
export default class SortImageText extends  Vue {

  private tableData = [{
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '0',
    action: '编辑 删除'
  }, {
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '1',
    action: '编辑 删除'
  }, {
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '2',
    action: '编辑 删除'
  }, {
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '3',
    action: '编辑 删除'
  }, {
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '4',
    action: '编辑 删除'
  }, {
    pic: '图',
    image: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg',
    title: '标题标题标题标题标题标题标题标题标题标题',
    isTurn: '是',
    no: '5',
    action: '编辑 删除'
  }];

  private mounted(): void {
    // console.log('messageMain');
  }
  private goBack(): void {
    this.$router.go(-1);
  }
  private CreateNewImageText() {
    this.$router.push({ name: 'CreateNewImageText'});
  }

  private SortImageText() {
    this.$router.go(-1);
  }
}

