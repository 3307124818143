export const DivisionRnage = [
  // {
  //   id: 1,
  //   name: 'Pharma'
  // },
  // {
  //   id: 2,
  //   name: 'Onco'
  // },
  // {
  //   id: 3,
  //   name: 'HRcore'
  // },
];

export const rulesConstructor = (i18n: any) => {
  return {
    profileName: [
      {
        required: true,
        message: i18n.common_content.content_info,
        trigger: ['blur', 'change']
      }
    ],
    userList: [
      {
        type: 'array',
        required: true,
        message: i18n.common_content.multiple_select_info,
        trigger: 'blur'
      }
    ],
    divisionRange: [
      {
        type: 'array',
        required: true,
        message: i18n.common_content.multiple_select_info,
        trigger: 'change'
      }
    ],
    authorityList: [
      {
        type: 'array',
        required: true,
        message: i18n.common_content.multiple_select_info,
        trigger: 'blur'
      }
    ]
  };
};
