export enum EditorStatus {
  edit = 'edit',
  create = 'create',
  save = 'save',
  save_as = 'save_as'
}

export enum ConsentStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED = 'EXPIRED'
}
