import { ISearchConfig } from '@/components/SearchTable/index.d';

export const DEPAETMENT = [
  { label: 'PHARMA', value: 'PHARMA' }
];

export const STATUSLIST = [
  { label: '草稿', value: 'DRAFT' },
  { label: '待生效', value: 'PUBLISHED' },
  { label: '生效', value: 'EFFECTIVE' },
  { label: '已失效', value: 'EXPIRED' }
];

export enum STATUSENUM {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  EFFECTIVE = 'EFFECTIVE',
  EXPIRED = 'EXPIRED'
}

export const searchConfig: ISearchConfig[] = [
  {
    category: 'common_content',
    subclass: 'protocol_name',
    type: 'input',
    key: 'name'
  },
  {
    category: 'consent_mgmt',
    subclass: 'relevance_protocol_name',
    type: 'select',
    key: 'replacedConsentId',
    resetOption: {
      value: 'id',
      label: 'name'
    },
    options: []
  },
  {
    category: 'consent_mgmt',
    subclass: 'create_time',
    type: 'datepicker',
    key: 'createTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    category: 'consent_mgmt',
    subclass: 'real_time',
    type: 'datepicker',
    key: 'effectiveTime',
    valueformat: 'yyyy-MM-dd HH:mm:ss'
  },
  {
    category: 'common_content',
    subclass: 'status',
    type: 'select',
    key: 'status',
    options: STATUSLIST
  }
];

export enum StatusCode {
  DRAFT = '草稿',
  PUBLISHED = '待生效',
  EFFECTIVE = '生效',
  EXPIRED = '已失效'
}
