


















































































































































































































































































import { dateFormat, Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { Component, Vue, Ref, Watch } from 'vue-property-decorator';
import { DialogService } from '@/services/common/dialog.service';
import UEditor from '@/components/rich-editor/rich-editor.vue';
import { DEPAETMENT, STATUSLIST, STATUSENUM } from '../consent.config';
import { ConsentService } from '@/services/consent-service';
import { EditorStatus, ConsentStatus } from '../index.interface';
import { getRules, stepList, requiredList } from './editor.config';
import moment from 'moment';
import { ElForm } from 'element-ui/types/form';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import {
  assign,
  find,
  isArray,
  some,
  includes,
  cloneDeep,
  findIndex
} from 'lodash';
import { UserUploadService } from '@/services/user-uploading';

@Component({
  components: {
    UEditor
  }
})
export default class ViewDialog extends Vue {
  private stepList: string[] = stepList;
  private active: number = 0;
  private rules = getRules();
  private loading: boolean = false;
  private data!: any;
  public statuslist: any[] = STATUSLIST;
  private editorStatus = EditorStatus;
  public defaultEffectiveTime: Date = new Date();
  public datePattern: string = 'yyyy-MM-dd HH:mm:ss';
  public interval!: number;
  public saveDisabled: boolean = false;
  public datePickerDisabled: boolean = false;
  public savedLoading: boolean = false;
  public formDisabled: boolean = true;
  public department: any[] = DEPAETMENT;
  public statusenum: Dict<any> = STATUSENUM;
  private requiredList: any[] = requiredList;
  private editFormCache!: Dict<any>;
  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  private form: any = {
    cancelReminder: '',
    content: '',
    departmentCode: [
      'PHARMA'
      // { id: '', label: '', name: '', value: '' }
    ],
    disclaimerList: [
      { required: '', disclaimer: '', key: new Date().getTime() }
    ],
    disclaimer: '',
    effectiveTime: '',
    name: '',
    status: ConsentStatus.DRAFT,
    type: 0
  };
  private agreementList: any[] = [];
  @Ref('form') private formRef!: ElForm;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(ConsentService) private consentService!: ConsentService;

  private async nextstep() {
    if (this.data.type === this.editorStatus.edit && this.formDisabled) {
      this.active =
        this.active === this.stepList.length
          ? this.stepList.length
          : this.active + 1;
    } else {
      const flag = this.form.id
        ? await this.handleeditSaved(this.editorStatus.save)
        : await this.handleSaved(this.editorStatus.save);
      if (flag) {
        this.active =
          this.active === this.stepList.length
            ? this.stepList.length
            : this.active + 1;
      }
    }

    if (this.active === this.stepList.length - 1) {
      if (
        this.data.row.status === ConsentStatus.DRAFT &&
        isArray(this.data.row.departmentCode) &&
        this.data.row.departmentCode.length > 0
      ) {
        await this.handleDepartment(this.data.row.departmentCode);
      }
      // this.form = assign(this.form, this.data.row);
    }
  }
  private prestep() {
    this.active = this.active === 0 ? 0 : this.active - 1;
  }

  private addueditor() {
    this.form.disclaimerList.push({
      required: '',
      disclaimer: '',
      key: new Date().getTime()
    });
  }

  private deleteUEditor(key: number) {
    const index = findIndex(this.form.disclaimerList, { key });
    this.form.disclaimerList.splice(index, 1);
  }

  public replacedConsentChangeHandler(val: string): void {
    this.$set(this.form, this.form.replacedConsentId, val);
  }

  public datePickerBlur(instance: any): void {
    const minus = Date.now() - new Date(instance.value).getTime();
    if (instance.value && minus > 0 && minus < 2000) {
      const now = new Date();
      now.setMinutes(now.getMinutes() + 3);
      this.form.effectiveTime = dateFormat(now, 'yyyy-MM-dd hh:mm:ss');
    }
  }
  // @Watch('form.effectiveTime')
  // public watchEffectiveTime(after: string, before: string) {
  //   if (!before && after) {
  //     setTimeout(() => {
  //       if (after && new Date(after).getTime() < Date.now()) {
  //         const now = new Date();
  //         now.setMinutes(now.getMinutes() + 3);
  //         this.form.effectiveTime = now;
  //       }
  //     }, 500);
  //   }
  // }

  public editDraft(): void {
    this.formDisabled = false;
    this.editFormCache = cloneDeep(this.form);
  }

  public cancelEdit(): void {
    this.formDisabled = true;
    this.form = this.editFormCache;
  }

  get pickerOptions(): Dict<any> {
    return {
      disabledDate(time: Date) {
        return time.getTime() < Date.now() - 8.64e7;
      },
      selectableRange: this.selectableStr // 可选时间范围
    };
  }

  get selectableStr(): string {
    const formatter = 'HH:mm:ss';
    const toNow = new Date(Date.now() + 1000 * 60 * 3);
    const now = new Date();
    now.setDate(now.getDate() + 1);
    now.setHours(0);
    now.setMinutes(0);
    now.setSeconds(0);
    const endofDayTemp = now.getTime() - 1000 * 60;
    if (endofDayTemp - toNow.getTime() <= 1000 * 60) {
      return '';
    } else {
      return (
        moment(toNow).format(formatter) +
        ' - ' +
        moment(endofDayTemp).format(formatter)
      );
    }
  }

  get publishTime(): string {
    if (this.data.row.publishTime) {
      return this.data.row.publishTime.split(' ')[0];
    } else {
      return '';
    }
  }

  public handlecancel(): void {
    this.dialog.close();
  }

  public clearReplacedConsent(): void {
    delete this.form.replacedConsentId;
    delete this.form.replacedConsentName;
    if (this.data && this.data.row) {
      delete this.data.row.replacedConsentId;
      delete this.data.row.replacedConsentName;
    }
  }

  public async handleSaved(type: EditorStatus): Promise<boolean> {
    if (await this.checkValid(type)) {
      try {
        this.savedLoading = true;
        const res = await this.consentService.create(this.form);
        this.form.id = res.id;
        this.$notify({
          title: '成功',
          type: 'success',
          message: '保存成功！'
        });
        return true;
        // this.dialog.close(CloseStatus.confirm);
        // this.data.refresh();
      } finally {
        this.savedLoading = false;
      }
    } else {
      return false;
    }
  }

  public async handleeditSaved(type: EditorStatus): Promise<boolean> {
    const isValid = await this.checkValid(type);
    if (isValid) {
      try {
        this.savedLoading = true;
        // debugger;
        await this.consentService.editConsent(this.form);
        this.$notify({
          title: '成功',
          type: 'success',
          message: '保存成功！'
        });
        return true;
      } finally {
        this.savedLoading = false;
        if (this.active === this.stepList.length - 1) {
          this.dialog.close(CloseStatus.confirm);
          this.data.refresh();
        }
      }
    } else {
      return false;
    }
  }

  public async handleDelete(): Promise<void> {
    await this.$confirm(`确定删除${this.data.row.name}？`, '提示', {
      confirmButtonText: '确定删除',
      cancelButtonText: '放弃',
      type: 'warning'
    });
    try {
      this.savedLoading = true;
      await this.consentService.delete(this.data.row.id);
      this.$notify({
        title: '成功',
        type: 'success',
        message: '删除成功！'
      });
      this.dialog.close(CloseStatus.confirm);
      this.data.refresh();
    } finally {
      this.savedLoading = false;
    }
  }

  public async mounted(): Promise<void> {
    // const division: any = (await this.userUploadService.getDepartmentdivision()) || [];
    // const divisionlist: any = [];
    // division.forEach((item: any, index: number) => {
    //   divisionlist.push({ id: item.deleted, label: item.division_name, name: item.id, value: item.division_name });
    // });
    // this.department = divisionlist;
    this.agreementList = [await this.consentService.getplatform('PHARMA')] || [];
    if (this.data.type === EditorStatus.edit) {
      // if (
      //   this.data.row.status === ConsentStatus.DRAFT &&
      //   isArray(this.data.row.departmentCode) &&
      //   this.data.row.departmentCode.length > 0
      // ) {
      //   await this.handleDepartment(this.data.row.departmentCode);
      // }
      this.form = assign(this.form, this.data.row);
      if (
        !some(this.agreementList, (e) => e.id === this.form.replacedConsentId) && this.form.replacedConsentId && this.form.replacedConsentName
      ) {
        this.agreementList.push({
          id: this.form.replacedConsentId,
          name: this.form.replacedConsentName
        });
      }
    }
    this.interval = setInterval(() => {
      this.defaultEffectiveTime = new Date();
    }, 1000);
  }

  public destroyed(): void {
    clearInterval(this.interval);
  }

  public async handleDepartment(value: string[]): Promise<void> {
    if (value.length > 0) {
      try {
        this.loading = true;
        this.agreementList =
          [await this.consentService.getplatform(value.join(','))] || [];
        if (
          !some(this.agreementList, (e: any) =>
            includes(
              [
                this.form.replacedConsentId,
                this.data && this.data.row && this.data.row.replacedConsentId
              ],
              e.id
            )
          )
        ) {
          this.clearReplacedConsent();
        }
        this.saveDisabled = false;
      } catch (e) {
        this.saveDisabled = true;
        this.clearReplacedConsent();
      } finally {
        this.loading = false;
      }
    }
  }

  private async checkValid(type: EditorStatus): Promise<boolean> {
    this.rules = getRules(type);
    const valid =
      (await this.formRef.validate()) && (await this.formRef.validate());
    if (!valid) {
      return false;
    }
    switch (type) {
      case EditorStatus.save_as:
        let banMessage: string = '';
        if (this.agreementList[0]) {
          if (this.agreementList[0].id && !this.form.replacedConsentId) {
            banMessage = '该部门已有生效的协议，请选择相关隐私协议后重新提交。';
          }
        } else {
          banMessage = '该部门已有待生效的协议，无法提交。';
        }
        if (new Date(this.form.effectiveTime).getTime() < Date.now()) {
          banMessage = '生效时间已过期，请修改生效时间。';
        }
        if (banMessage) {
          try {
            this.datePickerDisabled = true;
            await this.$alert('错误', {
              type: 'error',
              message: banMessage
            });
          } finally {
            this.datePickerDisabled = false;
          }
          return false;
        }
        const replacedConsentItem = find(
          this.agreementList,
          (e) => this.form.replacedConsentId === e.id
        );
        try {
          this.datePickerDisabled = true;
          const res = await this.$confirm(
            `确定发布${this.form.name}协议?${
              replacedConsentItem && replacedConsentItem.name
                ? ` ${replacedConsentItem.name}协议将会在${this.form.effectiveTime}失效。`
                : ` ${this.form.name}协议将会在${this.form.effectiveTime}生效。`
            }`,
            // 协议将会在${this.form.effectiveTime}生效。`,
            '提示',
            {
              confirmButtonText: '确定发布',
              cancelButtonText: '放弃发布',
              type: 'warning'
            }
          );
          if (!res) {
            return false;
          }
          this.form.status = ConsentStatus.PUBLISHED;
        } finally {
          this.datePickerDisabled = false;
        }
        break;
      case EditorStatus.save:
        this.form.status = ConsentStatus.DRAFT;
        break;
    }
    return true;
  }
}
