import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class ArticleService {
  @Inject(HttpService) private http!: HttpService;

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/content/articles', param, undefined, { headers: { 'Content-Type': 'multipart/form-data'}});
  }

  public getArticleList(): Promise<any> {
    return this.http.get('/content');
  }

  public delete(param: Dict<any>): Promise<any> {
    return this.http.delete('/content/' + param);
  }

  public edit(param: Dict<any>): Promise<any> {
    return this.http.put('/content/', param);
  }

  public get(param: Dict<any>): Promise<any> {
    return this.http.get('/content/' + param);
  }



}

