





































import { Component, Vue } from 'vue-property-decorator';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import { ManagerService } from '@/services/manager-service';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { HttpService } from '@/services/common/http.service';
@Component
export default class AddFunctionalAuthority extends Vue {
  [x: string]: any;
  // private dialogVisible: boolean = false;
  private initData: any[] = [];
  private checkList: any[] = [];
  private data!: any;
  // i8n
  @Inject(ManagerService) private managerService!: ManagerService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(HttpService) private http!: HttpService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  public async created(): Promise<void> {
    console.log(' this.data', this.data)
    var initDataCopy = this.data.initData;
    const result: any[] = await this.http.get('/api/admin/permissions');
    const Arrone: any[] = [];
    //去除数组中的标题项
    console.log('initDataCopy',initDataCopy)
    for (let i = 0; i < initDataCopy.length; i++) {
      if (initDataCopy[i].type != "title") {
        Arrone.push(initDataCopy[i])
      }
    }
    // 把与左侧菜单不相同的赋值为false 不可点击
    for (var j = 0; j < result.length; j++) {
      for (var k = 0; k < Arrone.length; k++) {
        if (Arrone[k].id == result[j].id) {
          Arrone[k].disabled = false;
         
        }
      }
    }
    // 把无disabled的赋值为true 不可点击
    for (var l = 0; l < Arrone.length; l++) {
      console.log(Arrone[l].disabled === undefined)
      if (Arrone[l].disabled === undefined) {
        Arrone[l].disabled = true;
      }
    }
    // 匹配操作后的数组 匹配赋值给页面数组
    for (let i = 0; i < initDataCopy.length; i++) {
      for (var l = 0; l < Arrone.length; l++) {
        if (Arrone[l].id == initDataCopy[i].id) {
          initDataCopy[i] = Arrone[l];
         
        }
      }
    }
    // 内容渲染
    this.initData = initDataCopy;
    this.checkList = this.data.checkList;
  }

  public submitForm(): void {
    const temp: any[] = [];
    this.checkList.forEach((item: number) => {
      const index = this.initData.findIndex(
        (inItem: any) => inItem.id === item
      );
      if (index !== -1) {
        temp.push(this.initData[index]);
      }
    });
    this.dialog.close(CloseStatus.confirm, temp.slice(0));
  }

  public cancelForm(): void {
    this.dialog.close();
  }
}
