


































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ArticleService } from '@/services/article-service';
import { Inject } from '@cds/common';

@Component({})
export default class EditNewImageText extends  Vue {

  // 新建分类对话框
  private dialogVisible: boolean = false;

  private formData: any = {
    id: '',
    title: '',
    sequenceNumber: '',
    watermarkType: '',
    description: '',
    articleCategoryId: '',
    sequeceNumberInCategory: '',
    content: '',
    externalLink: '',
    originalLink: '',
    isHistorical: ''
  };

  @Inject(ArticleService) private articleService!: ArticleService;

  private ArticleFormData: any[] = [];

  private async onSave(): Promise<void | boolean> {
    // this.form.validate(this.formValidHandler.bind(this));

    const result = await this.articleService.create(this.formData);
    let code: any = result.code;
    if (code === 200) {
      // this.createModalVisible = false;
      // this.clearForm();
      // this.onQuery();
      this.$message({
        message: 'save success',
        type: 'success'
      });
    }
  }


  private mounted(): void {
    // this.$set(this.formData,'title',this.ArticleFormData.title);
    this.onQuery();

  }
  private goBack(): void {
    this.$router.go(-1);
  }
  // 新建分类
  private createClass() {
    this.dialogVisible = true;
  }
  // 关闭分类对话框
  private closeClass() {
    this.dialogVisible = false;
  }

  private async onQuery(): Promise<void> {
    let id: any = this.$route.params.id;
    const list = await this.articleService.get(id);
    if (list) {
      this.formData = list;
    }
  }

  private async doEdit(param: any): Promise<void> {
    const result = await this.articleService.edit(this.formData);
    let code: any = result.code;
    if (code === 200) {
      // this.onQuery();
      this.$message({
        message: 'edit success',
        type: 'success'
      });
    }
  }

}

