





































































































































































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { Inject, CacheService } from '@cds/common';
import { ManagerService } from '@/services/manager-service';
import { ChannelService } from '@/services/channel-service';
import { I18nService } from '@cds/i18n';
import { DialogService } from '@/services/common/dialog.service';
import { DivisionRnage, rulesConstructor } from './index.config';
import { IAdminUser, IPcommonoption } from './index.d';
// API弹窗
import AddManagerUser from '@/components/addManagerUser/addManagerUser.vue';
import AddFunctionalAuthority from './dialogs/addFunctionalAuthority.vue';
import {
  cloneDeep,
  every,
  findIndex,
  forEach,
  isArray,
  isNumber,
  some,
  upperCase
} from 'lodash';
import { ElForm } from 'element-ui/types/form';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserService } from '@/services/user-service';

@Component
export default class Manager extends Vue {
  public isViewMode: boolean = false;
  public loginUserId: number = 0;
  public activeName: string = 'first';
  public loadingAppAuth: boolean = false;
  public divisionRnage: IPcommonoption[] = DivisionRnage;
  public apploading: boolean = false;
  public submitLoading: boolean = false;
  public rightloading: boolean = false;
  public loginRoleId: number = 0;
  // form表单校验规则
  public rules: any = rulesConstructor(this.i18n);
  // 权限菜单的index
  public menuActiveIndex: string = '';
  @Inject(ChannelService) public channelService!: ChannelService;
  private formData: Dict<any> = {
    userList: [],
    authorityList: [],
    profileName: '',
    profileId: null,
    divisionRange: [],
    appAuthorityList: {}
  };
  @Ref('form') private form!: ElForm;
  private currentUser!: IAdminUser;
  private appFunAcessList: any = {};
  private initAppAuthorityList: any = [];
  private appCallbackList: any = {}; // 应用管理权限缓存
  private appAuthList: any[] = [];
  private isSuperAdmin: boolean = false;
  private roleMenu: any[] = [];
  private accessInfoList: any = {}; // accessibility 修改警告提示
  @Inject(ManagerService) private managerService!: ManagerService;
  @Inject(UserService) private userService!: UserService;
  @Inject(CacheService) private cacheSvc!: CacheService;
  @Inject(DialogService) private dialog!: DialogService;
  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  // 判断应用权限管理中 应用访问权限维度控制 是否必填
  get isSelectedAppAuthoruty(): boolean {
    const res: boolean = every(
      this.appCallbackList,
      (item: any, key: string) => {
        // functionlist或者management 有一个填写了内容accessibility必须填写内容
        const index = findIndex(
          this.appAuthList,
          (e) => `channelId${e.id}` === key
        );
        if (
          ((item.functionlist && item.functionlist.length > 0) ||
            (item.management && item.management.length > 0)) &&
          !!this.appAuthList[index]
        ) {
          if (item.accessibility && item.accessibility.length > 0) {
            if (this.appAuthList[index].isAppAuthInvalid) {
              this.$set(this.appAuthList[index], 'isAppAuthInvalid', false);
            }
            return true;
          } else {
            this.$set(this.appAuthList[index], 'isAppAuthInvalid', true);
            return false;
          }
        } else {
          return true;
        }
      }
    );
    return res;
  }

  public getTagName(name?: string, i18n?: Dict<string>): string {
    if (name) {
      const strArr = name.split('.');
      if (strArr.length > 1) {
        return this.getTagName(
          strArr.slice(1).join('.'),
          ((i18n || this.i18n) as Dict<any>)[strArr[0]]
        );
      } else {
        return ((i18n || this.i18n) as Dict<any>)[strArr[0]] || name;
      }
    } else {
      return '';
    }
  }

  public async refreshAppAuths(): Promise<void> {
    if (this.formData.profileId) {
      await this.getAppAuthority(true);
      await this.bindAppAuths(this.formData.profileId);
    }
  }

  public created(): void {
    this.currentUser = this.cacheSvc.get('user');
    this.loginUserId = this.currentUser.id;
    this.loginRoleId = this.currentUser.roleId as number;
    this.checkSuperAdmin();
  }

  public async mounted(): Promise<void> {
    await this.onQuery();
    await this.getDepartmentdivision()
  }

  public handleAccessibility(id: any) {
    this.accessInfoList[`channelId${id}`] = true;
  }

  public async checkSuperAdmin() {
    let result = await this.userService.checkUserAdminStatus();
    this.isSuperAdmin = (result.isSuperAdmin == 1)
  }

  // 创建权限
  public addAuthority() {
    const index = this.roleMenu.findIndex(
      (item: any) => !isNumber(item.profileId)
    );
    if (index === -1) {
      this.roleMenu.push({
        profileId: null,
        profileName: this.i18n.admin_mgmt.app_admin
      });
      this.menuActiveIndex = this.roleMenu.length.toString();
      this.initForm();
    }
  }

  // 管理用户范围里面的item删除方法
  public divisionChange(value: string): void {
    if (value === 'Pharma') {
      this.handleTagDelete(
        {
          id: 7
        },
        this.formData.authorityList,
        'id'
      );
    }
  }

  // 删除权限
  public async deleteAuthority(id: number, index: number, name: string) {
    await this.$confirm(
      `确定删除管理组"${name}"? 如确定删除会删除管理员"${this.formData.userList
        .map((e: any) => e.fullName)
        .join(',')}"`,
      '提示',
      {
        confirmButtonText: '确定删除',
        cancelButtonText: '放弃',
        type: 'warning'
      }
    );
    if (id) {
      await this.managerService.deleteProfile(id);
      this.menuActiveIndex = this.roleMenu[0].profileId.toString();
      this.getRolesMenu();
    } else {
      this.roleMenu.splice(index, 1);
    }
    this.$notify({
      title: '成功',
      type: 'success',
      message: '删除成功！'
    });
  }

  // 保存form
  public async submitForm(type?: string): Promise<void> {
    const isSelectedAppAuthoruty = this.isSelectedAppAuthoruty;
    const isValid = await this.form.validate();
    if (isSelectedAppAuthoruty && isValid) {
      try {
        this.submitLoading = true;
        //  判断是提交还是创建 profileId 是否为空
        const temp = cloneDeep(this.formData);
        temp.appAuthorityList = this.createNewAppAuthList();
        if (temp.profileId && !type) {
          await this.managerService.putProfile(temp);
        } else {
          // 另存为新
          await this.managerService.postProfile(temp);
          await this.getAppAuthorityList();
        }
        await this.getRolesMenu();
        this.menuActiveIndex = this.roleMenu[0].profileId;
        this.$notify({
          title: '成功',
          type: 'success',
          message: '保存成功！'
        });
      } finally {
        this.submitLoading = false;
      }
    }
  }


  // 添加管理员
  public async addManagerUser(): Promise<void> {
    const result = await this.dialog.open(
      '选择管理员',
      AddManagerUser,
      {
        list: this.formData.userList
      },
      {
        height: 'auto',
        width: 'auto'
      }
    );
    if (result.action === CloseStatus.confirm) {
      this.formData.userList = result.result;
    }
  }

  // 管理用户范围
  private async getDepartmentdivision(): Promise<void> {
    // 1.formData是需要点击保存的时候传给后端的参数。
    // 2.下面调用的接口暂时是通讯录里面的division数据接口：getDepartmentdivision
    const res = await this.managerService.getDepartmentdivision()
    this.divisionRnage = []
    res.forEach((item: any) => {
      this.divisionRnage.push({ id: item.id, name: item.division_name })
    });
  }

  // 平台功能权限
  public async addFunctionalAuthority(): Promise<void> {
    // 管理用户范围选择哪项对应的平台功能权限中的PHARMA或ONCO是否可以选中
    const TYPELIST = ['PHARMA'];
    // 保存pharma和onco的位置对象
    const typeObj: Dict<any> = {};
    // 初始化initAppAuthorityList 设置Pharma 和 Onco的disabled 为true
    TYPELIST.forEach((item: string) => {
      const index = this.initAppAuthorityList.findIndex((initem: Dict<any>) => {
        if (initem && initem.name) {
          const name = upperCase(initem && initem.name);
          return name.indexOf(item) !== -1;
        }
      });

      if (index >= 0) {
        typeObj[item] = index;
        this.initAppAuthorityList[index].disabled = true;
      }
    });

    let adminManagerIndex = this.initAppAuthorityList.findIndex((appAuth: Dict<any>) =>
      appAuth.id == 10000
    );
    if (adminManagerIndex == -1 || !this.isSuperAdmin) {
      this.initAppAuthorityList[adminManagerIndex].disabled = true;
    }
    console.log(this.formData.divisionRange);
    console.log(this.initAppAuthorityList);
    console.log(typeObj);

    this.formData.divisionRange.forEach((item: string) => {
      if (item && upperCase(item).indexOf('PHARMA') != -1 && typeObj['PHARMA']) {
        this.initAppAuthorityList[typeObj['PHARMA']].disabled = false;
      }
    });
    this.loadingFull(1000);
    const result = await this.dialog.open(
      '平台功能权限',
      AddFunctionalAuthority,
      {
        initData: this.initAppAuthorityList,
        checkList: this.formData.authorityList
          .filter((e: any) => !!e)
          .map((item: any) => item.id)
      },
      {
        height: 'auto',
        width: 'auto'
      }
    );
    if (result.action === CloseStatus.confirm) {
      this.formData.authorityList = result.result;
    }
  }

  private i18nFormate(value: string): string[] | string {
    if (!value) {
      return '';
    }
    return value.split('.');
  }

  // 初始化表单
  private initForm(): void {
    this.formData = {
      userList: [
        {
          disabledFlag: 1,
          email: this.currentUser.email,
          employeeId: this.currentUser.employeeId || '',
          fullName: this.currentUser.fullName,
          id: this.currentUser.id
        }
      ],
      authorityList: [],
      profileName: '',
      profileId: null,
      divisionRange: [],
      appAuthorityList: {}
    };
    for (const key of Object.keys(this.appCallbackList)) {
      this.appCallbackList[key] = {
        management: [],
        accessibility: [],
        functionlist: []
      };
    }
    this.appFunAcessList = {};
    this.isViewMode = false;
  }

  private createNewAppAuthList(): Dict<any> {
    let management: any[] = [];
    let accessibility: any[] = [];
    let functionlist: any[] = [];
    for (const key of Object.keys(this.appCallbackList)) {
      management = management.concat(this.appCallbackList[key].management);
      accessibility = accessibility.concat(
        this.appCallbackList[key].accessibility
      );
      functionlist = functionlist.concat(
        this.appCallbackList[key].functionlist
      );
    }
    return { management, accessibility, functionlist };
  }

  // 获取应用管理
  private async getAppAuthority(showNodify?: boolean): Promise<void> {
    this.loadingAppAuth = true;
    const res = await this.managerService.getProfileAppAuthorityList();
    if (!res) {
      return;
    }
    this.appAuthList = res;
    // 初始化应用管理权限
    this.appAuthList.forEach((item: any) => {
      this.$set(this.appCallbackList, `channelId${item.id}`, {
        management: [],
        accessibility: [],
        functionlist: []
      });
    });
    if (showNodify) {
      this.$notify({
        title: '刷新成功',
        type: 'success',
        message: `${res.length}条刷新。`
      });
    }
    this.loadingAppAuth = false;
  }

  private async bindAppAuths(id: number): Promise<Dict<any> | undefined> {
    const res = await this.managerService.getProfileByid(id);
    if (!res) {
      return;
    }
    this.isViewMode =
      res.createUserEmployeeId !== this.currentUser.employeeId;
    const { appAuthorityList } = res;
    if (!isArray(this.appAuthList) || this.appAuthList.length === 0) {
      await this.getAppAuthority();
    }
    if (isArray(appAuthorityList) && appAuthorityList.length > 0) {
      forEach(appAuthorityList, (item: any) => {
        if (some(this.appAuthList, (e) => e.id === item.channelId)) {
          this.appCallbackList[`channelId${item.channelId}`] = {
            management: item.management,
            accessibility: item.accessibility,
            functionlist: item.functionlist
          };
          if (isArray(item.functionlist) && item.functionlist.length > 0) {
            this.appFunAcessList[`channelId${item.channelId}`] = true;
          }
        }
      });
    }
    return res;
  }

  // left 左侧切换菜单
  private async chooseMenu(id: number): Promise<void> {
    this.rightloading = true;
    if (id && isNumber(id)) {
      this.menuActiveIndex = String(id);
    }
    if (!id) {
      this.initForm();
      this.rightloading = false;
      return;
    }
    this.formData.profileId = id;
    try {
      for (const key of Object.keys(this.appCallbackList)) {
        this.appCallbackList[key] = {
          management: [],
          accessibility: [],
          functionlist: []
        };
      }
      this.appFunAcessList = {};
      this.formData = (await this.bindAppAuths(id)) || {};
    } finally {
      this.rightloading = false;
    }
  }

  // 删除标签tag
  private handleTagDelete(obj: any, data: any, key: string): void {
    const index = data.findIndex((item: any) => item[key] === obj[key]);
    if (index > -1) {
      data.splice(index, 1);
    }
  }

  // init 获取用户左侧roles 和 select 权限列表
  private async getRolesMenu(): Promise<void> {
    this.rightloading = true;
    try {
      const res = await this.managerService.getProfilList();
      if (!res || res.length === 0) {
        return;
      }
      this.roleMenu = res;
      this.formData.profileId = this.formData.profileId || res[0].profileId;
      // 刷新菜单后list按修改顺序排序总是获取数组第一个
      this.chooseMenu(res[0].profileId);
    } finally {
      this.rightloading = false;
    }
  }

  // 获取平台功能权限
  private async getAppAuthorityList(): Promise<void> {
    try {
      const res = await this.managerService.getProfileAuthorityList({});
      if (!res) {
        return;
      }
      const temp: any[] = [];
      res.forEach((outItem: any) => {
        temp.push({
          id: outItem.id,
          name: this.i18n[this.i18nFormate(outItem.name)[0]][
            this.i18nFormate(outItem.name)[1]
          ],
          type: 'title'
        });
        outItem.children.forEach((item: any) => {
          temp.push({
            id: item.id,
            name: this.i18n[this.i18nFormate(item.name)[0]][
              this.i18nFormate(item.name)[1]
            ]
          });
        });
      });
      this.initAppAuthorityList = temp;
    } catch (error) {
      throw new Error(error);
    }
  }

  private async onQuery(): Promise<void> {
    await this.getAppAuthority();
    await this.getRolesMenu();
    await this.getAppAuthorityList();
  }
  // 加loading
  private loadingFull(time: number | undefined): void {
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(() => {
      loading.close();
    }, time);
  }
}
