import { render, staticRenderFns } from "./editprotocol.vue?vue&type=template&id=d3b8498e&scoped=true&"
import script from "./editprotocol.vue?vue&type=script&lang=ts&"
export * from "./editprotocol.vue?vue&type=script&lang=ts&"
import style0 from "./editprotocol.vue?vue&type=style&index=0&id=d3b8498e&lang=scss&scoped=true&"
import style1 from "./editprotocol.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3b8498e",
  null
  
)

export default component.exports