import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { isFunction, map } from 'lodash';
import { getInsertPluginList } from './config';
import { EditorCommand, EditorEvent, IEditor, IEditorConf } from './editor.interface';
declare global {
  interface Window {
    UE: {
      getEditor: (
        id: string,
        option: IEditorConf & { UEDITOR_HOME_URL: string }
      ) => IEditor;
      I18N: Dict<any>;
      registerUI: (
        name: string,
        callback: (editor: any, uid: string) => any,
        index?: number[],
        editorId?: string
      ) => void;
      ui: Dict<any>;
      plugins: Dict<any>;
    };
    clean_135helper: () => void;
    current_editor: IEditor;
  }
}
export class MaterialPluginService {
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public registry(component: any, id?: string): void {
    // return new Promise((resolve) => {
    if (window.UE) {
      const registerUINames = map(getInsertPluginList(this.i18n), (e, i) => i).join(' ');
      // forEach(getInsertPluginList(this.i18n), (e, i) => this.registryPlugin(i));

      window.UE.registerUI(registerUINames, (editor: IEditor, uiName: string) => {
        // 注册按钮执行时的command命令，使用命令默认就会带有回退操作
        editor.registerCommand(uiName, {
          execCommand: async () => {
            const uid = uuid();
            const res = await this.dialog.open('素材选择', component, {
              key: uid,
              uiName
            }, {
              key: uid
            });
            editor.execCommand(EditorCommand.inserthtml, res.result);
          }
        });
        // resolve();
        return this.createButton(editor, uiName);
      }, void 0, id);
    }
    // });
  }

  // private registryPlugin(uiname: string): void {
  //   if (window.UE) {
  //     const UE = window.UE;
  //     UE.plugins[uiname] = function () {
  //       const editor: IEditor = this;
  //       editor.commands[uiname] = {
  //         execCommand: () => {
  //           alert('execCommand:' + uiname);
  //         }
  //       }
  //     }
  //   }
  // }

  private createButton(editor: IEditor, uiName: string): any {
    const UE = window.UE;
    // 创建一个button
    const btn = new UE.ui.Button({
      // 按钮的名字
      name: uiName,
      // 提示
      title: getInsertPluginList(this.i18n)[uiName],
      // 添加额外样式，指定icon图标，这里默认使用一个重复的icon
      cssRules: `background-image: url(/img/${uiName}.png) !important;background-size: cover;`,
      // 点击时执行的命令
      onclick: () => {
        // 这里可以不用执行命令,做你自己的操作也可
        editor.execCommand(uiName as any);
      }
    });
    this.setListener(editor, uiName, btn);
    return btn;
  }

  private setListener(editor: IEditor, uiName: string, element: any): void {
    // 当点到编辑内容上时，按钮要做的状态反射
    editor.addListener(EditorEvent.selectionchange, () => {
      const state = editor.queryCommandState(uiName);
      if (state === -1 && isFunction(element.setDisabled) && isFunction(element.setChecked)) {
        element.setDisabled(true);
        element.setChecked(false);
      } else {
        element.setDisabled(false);
        element.setChecked(state);
      }
    });
  }
}
