// import { IInsertPluginItem } from "./editor.interface";

import { MaterialType } from './editor.interface';

const APP_KEY = process.env.VUE_APP_UEDITOR_KEY;

const PLAT_HOST = 'www.135editor.com';

const BASE_URL = `https://${PLAT_HOST}`;

export const EditorSourceConfig = {
  scripts: [
    'ueditor.config.js',
    'third-party/jquery-3.7.0.min.js',
    'ueditor.all.min.js',
    'ueditor.common.js',
    // 'third-party/jquery-colpick/js/colpick.js'
  ],
  endScripts: [
    'end.js',
  ],
  videoScripts: [
    'ueditor.parse.min.js',
    'video.js'
  ],
  css: [
    'themes/96619a5672.css',
    // 'third-party/jquery-colpick/css/colpick.css'
  ]
};

export const defaultEditorConfig = {
  path: `${process.env.BASE_URL}ueditor/`,
  plat_host: PLAT_HOST,
  appkey: APP_KEY,
  // open_editor: true,
  // pageLoad: true,
  style_url:
    BASE_URL + '/editor_styles/open?inajax=1&appkey=' + APP_KEY,
  page_url:
    BASE_URL +
    '/editor_styles/open_styles?inajax=1&appkey=' +
    APP_KEY,
  style_width: 340,
  uploadFormData: {
    referer: window.document.referrer,
  },
  zIndex: 2500,
  // focus: true,
  // autoFloatEnabled: false,
  // autoHeightEnabled: false,
  // scaleEnabled: false,
  // focusInEnd: true,
  enableEndToolbar: true,
  enableVideo: true
};

export function getInsertPluginList(i18n: Dict<any>): Dict<string> {
  return {
    [MaterialType.insertcustomimagetext]: i18n.custon_plugins.insertImageText,
    [MaterialType.insertcustomvideo]: i18n.custon_plugins.insertVideo,
    [MaterialType.insertcustomaudio]: i18n.custon_plugins.insertAudio,
    [MaterialType.insertcustomimage]: i18n.custon_plugins.insertImage,
    [MaterialType.insertcustomfile]: i18n.custon_plugins.insertFile
  };
}
