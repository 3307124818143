var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dialog"},[_c('div',{staticClass:"form-box-title"},[(_vm.data.type === _vm.editorStatus.create)?_c('p',[_vm._v("----")]):_c('p',[_vm._v(_vm._s(_vm.i18n.consent_mgmt.release_time)+"："+_vm._s(_vm.publishTime))]),_c('div',{staticClass:"form-box-title-status"},[_c('p',[_vm._v(_vm._s(_vm.i18n.common_content.status))]),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"disabled":true,"placeholder":"请选择"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l((_vm.statuslist),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)]),_c('el-steps',{staticStyle:{"margin-top":"1rem"},attrs:{"active":_vm.active,"finish-status":"success","simple":""}},_vm._l((_vm.stepList),function(item,index){return _c('el-step',{key:index,attrs:{"title":item}})}),1),_c('div',{staticClass:"step-body"},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"form",staticClass:"form-box",attrs:{"label-width":"auto","model":_vm.form,"rules":_vm.rules[_vm.active]}},[(_vm.active === 0)?_c('section',[_c('el-form-item',{attrs:{"label":_vm.i18n.consent_mgmt.privacy_consent_setting,"prop":"name","label-position":"left","label-width":"150px"}},[_c('el-input',{attrs:{"disabled":_vm.data.type === _vm.editorStatus.edit && _vm.formDisabled},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}},[_c('template',{slot:"prepend"},[_vm._v(_vm._s(_vm.i18n.consent_mgmt.platform_agreement))])],2)],1)],1):_vm._e(),(_vm.active === 1)?_c('section',[_c('el-form-item',{attrs:{"label":_vm.i18n.admin_portal.consent_description,"prop":"content","label-position":"top","label-width":"auto"}},[_c('UEditor',{attrs:{"config":{
                enableEndToolbar: false,
                disabled: _vm.data.type === _vm.editorStatus.edit && _vm.formDisabled
              }},model:{value:(_vm.form.content),callback:function ($$v) {_vm.$set(_vm.form, "content", $$v)},expression:"form.content"}})],1)],1):_vm._e(),(_vm.active === 2)?_c('section',[_vm._l((_vm.form.disclaimerList),function(disclaimeritem,index){return [_c('el-form-item',{key:((disclaimeritem.key) + "required"),attrs:{"label":("选项 " + (index + 1)),"label-position":"right","prop":("disclaimerList." + index + ".required"),"rules":{
                required: true,
                message: '请选择选项',
                trigger: 'blur'
              }}},[_c('el-select',{staticStyle:{"width":"20%"},attrs:{"size":"small","disabled":_vm.data.type === _vm.editorStatus.edit && _vm.formDisabled,"placeholder":"请选择"},model:{value:(disclaimeritem.required),callback:function ($$v) {_vm.$set(disclaimeritem, "required", $$v)},expression:"disclaimeritem.required"}},_vm._l((_vm.requiredList),function(requireditem){return _c('el-option',{key:requireditem.value,attrs:{"label":requireditem.label,"value":requireditem.value}})}),1),(
                  (_vm.data.type === _vm.editorStatus.edit && !_vm.formDisabled) ||
                    _vm.data.type === _vm.editorStatus.create
                )?_c('el-button',{staticClass:"form-item-btn",attrs:{"size":"mini","type":"danger"},on:{"click":function($event){return _vm.deleteUEditor(disclaimeritem.key)}}},[_vm._v("删除")]):_vm._e()],1),_c('el-form-item',{key:((disclaimeritem.key) + "disclaimer"),attrs:{"label-position":"top","prop":("disclaimerList." + index + ".disclaimer"),"rules":[
                { required: true, message: '请输入免责声明', trigger: 'blur' }
              ]}},[_c('UEditor',{attrs:{"config":{
                  enableEndToolbar: false,
                  disabled: _vm.data.type === _vm.editorStatus.edit && _vm.formDisabled
                }},model:{value:(disclaimeritem.disclaimer),callback:function ($$v) {_vm.$set(disclaimeritem, "disclaimer", $$v)},expression:"disclaimeritem.disclaimer"}})],1)]}),(
              _vm.data.type === _vm.editorStatus.create ||
                (_vm.data.type === _vm.editorStatus.edit && !_vm.formDisabled)
            )?_c('el-button',{on:{"click":_vm.addueditor}},[_vm._v("创建新选项")]):_vm._e()],2):_vm._e(),(_vm.active === 3)?_c('section',[_c('el-form-item',{attrs:{"label":_vm.i18n.admin_portal.cancel_remind,"prop":"cancelReminder"}},[_c('el-input',{attrs:{"type":"textarea","autosize":{ minRows: 2, maxRows: 4 },"disabled":_vm.data.type === _vm.editorStatus.edit && _vm.formDisabled},model:{value:(_vm.form.cancelReminder),callback:function ($$v) {_vm.$set(_vm.form, "cancelReminder", $$v)},expression:"form.cancelReminder"}})],1),_c('el-divider'),_c('el-form-item',{attrs:{"label":_vm.i18n.consent_mgmt.relevant_privacy_agreement,"prop":"content"}},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"disabled":_vm.data.type === _vm.editorStatus.edit && _vm.formDisabled,"size":"small","clearable":"","placeholder":"请选择"},on:{"change":_vm.replacedConsentChangeHandler},model:{value:(_vm.form.replacedConsentId),callback:function ($$v) {_vm.$set(_vm.form, "replacedConsentId", $$v)},expression:"form.replacedConsentId"}},_vm._l((_vm.agreementList),function(item,index){return _c('el-option',{key:index,attrs:{"label":item.name,"value":item.id}},[_vm._v(_vm._s(item.name)+" ")])}),1)],1),_c('el-form-item',{attrs:{"label":_vm.i18n.consent_mgmt.real_time,"prop":"effectiveTime"}},[_c('el-date-picker',{staticStyle:{"width":"100%"},attrs:{"disabled":(_vm.data.type === _vm.editorStatus.edit && _vm.formDisabled) ||
                  _vm.datePickerDisabled,"value-format":_vm.datePattern,"default-value":_vm.defaultEffectiveTime,"type":"datetime","placeholder":"选择日期时间","picker-options":_vm.pickerOptions},on:{"blur":_vm.datePickerBlur},model:{value:(_vm.form.effectiveTime),callback:function ($$v) {_vm.$set(_vm.form, "effectiveTime", $$v)},expression:"form.effectiveTime"}})],1)],1):_vm._e()])],1),_c('div',{staticClass:"step-footer"},[(_vm.form.status === 'DRAFT' && _vm.data.type === _vm.editorStatus.edit)?[(_vm.formDisabled)?_c('el-button',{attrs:{"type":"danger","plain":!_vm.formDisabled},on:{"click":_vm.handleDelete}},[_vm._v(_vm._s(_vm.i18n.common_content.delete)+" ")]):_vm._e(),(_vm.formDisabled)?_c('el-button',{attrs:{"type":"primary","plain":""},on:{"click":_vm.editDraft}},[_vm._v(_vm._s(_vm.i18n.common_content.edit)+" ")]):_vm._e()]:_vm._e(),(_vm.active > 0)?_c('el-button',{on:{"click":_vm.prestep}},[_vm._v("上一步")]):_vm._e(),(_vm.active < _vm.stepList.length - 1)?_c('el-button',{on:{"click":_vm.nextstep}},[_vm._v("下一步")]):_vm._e(),(
          (_vm.form.status === 'DRAFT' &&
            _vm.data.type === _vm.editorStatus.create &&
            _vm.active === _vm.stepList.length - 1) ||
            (_vm.data.type === _vm.editorStatus.edit && !_vm.formDisabled &&
            _vm.active === _vm.stepList.length - 1)
        )?[_c('el-button',{attrs:{"disabled":_vm.saveDisabled},on:{"click":function($event){return _vm.handleeditSaved(_vm.editorStatus.save)}}},[_vm._v("保存")]),_c('el-button',{attrs:{"disabled":_vm.saveDisabled,"type":"primary"},on:{"click":function($event){return _vm.handleeditSaved(_vm.editorStatus.save_as)}}},[_vm._v("发布 ")])]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }