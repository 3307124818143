










import { Component, Vue } from 'vue-property-decorator';
import { ICategoryItem } from './material.interface';
import { MaterialCategory } from './config';
import MaterialMain from './material/materialMain.vue';
import { Inject } from '@cds/common';
import { DialogService } from '@/services/common/dialog.service';
import { CloseStatus } from '@/services/common/models/dialog.interface';

@Component({
  components: {
    MaterialMain
  }
})
export default class MaterialSelector extends Vue {
  public materialCategories: ICategoryItem[] = MaterialCategory;
  private data!: any;
  @Inject(DialogService) private dialog!: DialogService;
  public selectHandler(data: any): void {
    this.dialog.close(CloseStatus.confirm, data || '<img alt="图片未找到"/>', this.data.key);
  }
}
