export const channellist = [
  {
    label: '第三方跳转',
    value: 'third'
  },
  {
    label: '其他诺华应用',
    value: 'other'
  },
  {
    label: '不跳转',
    value: 'none'
  }
];

export const channelobj: any = {
  third: {
    title: '三方跳转提醒',
    content:
      '您即将要离开诺华e家进入第三方网站。您和该三方网站的互动将受您和该三方平台之间的隐私政策约束，由您自行判断并独立承担责任。'
  },
  other: {
    title: '其他诺华应用跳转提醒',
    content: '您即将要离开诺华e家进入其他诺华应用。'
  },
  none: {}
};
