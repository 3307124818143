































































import { DialogService } from '@/services/common/dialog.service';
import { uuid } from '@/services/utils';
import { Inject } from '@cds/common';
import { Component, Emit, Ref, Vue } from 'vue-property-decorator';
import CreateNewImageText from './createNewImageText.vue';
import EditNewImageText from './editNewImageText.vue';
import SortImageText from './sortImageText.vue';

@Component({})
export default class VideoMaterial extends Vue {
  public fit: string = '';
  @Inject(DialogService) private dialog!: DialogService;
  public async CreateNewImageText(): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '新建视频',
      CreateNewImageText,
      { key },
      { key, width: window.innerWidth * 0.85 }
    );
  }

  public async onEdit(data: any): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '编辑视频',
      EditNewImageText,
      { key, ...data },
      { key, width: window.innerWidth * 0.85 }
    );
  }

  public async SortImageText(): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '排序',
      SortImageText,
      { key },
      { key, width: window.innerWidth * 0.85 }
    );
  }

  @Emit('select')
  public selectHandler(data: any): void {
    return data;
  }
}
