export const ChannelMock = [
  {
    countryCode: '002',
    departmentCode: '2',
    divisionCode: '123',
    email: 'ssss@live.cn',
    firstName: 'ye',
    id: 1,
    lastName: 'zhenchi',
    siteCode: '233',
    staffNo: '333',
    userId: '1',
    createTime: '2020-12-20',
    status: true
  },
  {
    countryCode: '002',
    departmentCode: '2',
    divisionCode: '123',
    email: 'ssss@live.cn',
    firstName: 'ye',
    id: 2,
    lastName: 'zhenchi',
    siteCode: '233',
    staffNo: '333',
    userId: '2',
    createTime: '2020-12-20',
    status: false
  },
  {
    countryCode: '002',
    departmentCode: '2',
    divisionCode: '123',
    email: 'ssss@live.cn',
    firstName: 'ye',
    id: 3,
    lastName: 'zhenchi',
    siteCode: '233',
    staffNo: '333',
    userId: '3',
    createTime: '2020-12-20',
    status: true

  },
  {
    countryCode: '002',
    departmentCode: '2',
    divisionCode: '123',
    email: 'ssss@live.cn',
    firstName: 'ye',
    id: 4,
    lastName: 'zhenchi',
    siteCode: '233',
    staffNo: '333',
    userId: '4',
    createTime: '2020-12-20',
    status: true
  }];
