














































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { ArticleService } from '@/services/article-service';
import { CategoryService } from '@/services/category-service';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';

@Component({})
export default class CreateNewImageText extends Vue {
  @Inject(ArticleService) private articleService!: ArticleService;
  @Inject(CategoryService) private categoryService!: CategoryService;

  @Inject(I18nService) private i18nSvc!: I18nService;

  private formDateArticle = new FormData();

  private rulesCategory: any = {
    name: [
      {
        required: true,
        message: '请输入名称',
        trigger: ['blur', 'change']
      },
      {
        min: 0,
        max: 100,
        message: '长度过长',
        trigger: ['blur', 'change']
      }
    ]
  };

  private rules: any = {
    title: [
      {
        required: true,
        message: '请输入标题',
        trigger: ['blur', 'change']
      },
      {
        min: 0,
        max: 100,
        message: '长度过长',
        trigger: ['blur', 'change']
      }
    ]
  };

  // 新建分类对话框
  private dialogVisible: boolean = false;
  private preDialogVisible: boolean = false;
  private imageUrl: string = ''; // 默认图片地址
  private groupName: string = '';
  private groupRemark: string = '';
  private isFile: boolean = true;

  private preMessage: string = '';

  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  private formDataCategory: any = {
    id: '',
    description: '',
    name: ''
  };

  private list: any = ['2'];

  private formData: any = {
    id: '',
    title: '',
    sequenceNumber: '',
    watermarkType: '',
    description: '',
    articleCategoryId: '',
    sequeceNumberInCategory: '',
    content: '',
    externalLink: '',
    originalLink: '',
    isHistorical: ''
  };

  private beforeUploadForm(file: any): void {
    // debugger;
    if (typeof file !== 'undefined' && file.size > 0) {
      this.isFile = false;
      // console.log('1121232');
    } else {
      this.isFile = true;
      // console.log('11211111111111232');
    }

    //  this.isFile = false;
    this.formDateArticle.append('file', file);
  }

  private handleChange(file: any): void {
    this.isFile = false;
  }

  private handleRemove(file: any): void {
    this.isFile = true;
    // console.log('11111111111112');
  }

  private beforeUploadHistorical(file: any): void {
    // debugger;
    if (file.size > 0) {
      this.formDateArticle.append('fileHistorical', file);
    }
  }

  private onPre(): any {
    this.preDialogVisible = true;
    this.preMessage =
      '<div style="font-size: 18px">test1</div>' +
      '<div style="font-size: 18px">test2</div>';
  }

  private async onSave(): Promise<void | boolean> {
    // this.form.validate(this.formValidHandler.bind(this));

    if (this.isFile) {
      this.$message.error('请上传封面文件');
      return;
    }
    (this.$refs.upload as any).submit();
    (this.$refs.uploadhistorical as any).submit();

    this.formDateArticle.append('title', this.formData.title); // 必须不能为空  需要判断下
    this.formDateArticle.append(
      'articleCategoryId',
      this.formData.articleCategoryId
    );
    this.formDateArticle.append('content', this.formData.content);
    this.formDateArticle.append('description', this.formData.description);
    this.formDateArticle.append('externalLink', this.formData.externalLink);
    this.formDateArticle.append('isHistorical', this.formData.isHistorical);
    this.formDateArticle.append('originalLink', this.formData.originalLink);
    this.formDateArticle.append('sequenceNumber', this.formData.sequenceNumber);
    this.formDateArticle.append('watermarkType', this.formData.watermarkType);
    this.formDateArticle.append(
      'sequeceNumberInCategory',
      this.formData.sequeceNumberInCategory
    );

    const result = await this.articleService.create(this.formDateArticle);
    let code: any = result.code;
    if (code === 200) {
      // this.createModalVisible = false;
      // this.clearForm();
      // this.onQuery();
      this.$message({
        message: 'save success',
        type: 'success'
      });
    }
  }

  private async onCategorySave(): Promise<void | boolean> {
    const result = await this.categoryService.create(this.formDataCategory);
    let code: any = result.code;
    if (code === 200) {
      this.$message({
        message: 'save success',
        type: 'success'
      });
    }
  }

  private mounted(): void {
    // console.log('messageMain');
  }
  private goBack(): void {
    this.$router.go(-1);
  }
  // 新建分类
  private createClass() {
    this.dialogVisible = true;
  }
  private sequeceNumberChange(value: any) {
    // console.log(value);
  }
  // 关闭分类对话框
  private closeClass() {
    this.dialogVisible = false;
  }

  private addArticleCard() {
    this.list.push('');
  }
}
