












































































import { Component, Ref, Vue } from 'vue-property-decorator';
import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { searchConfig, StatusCode } from './consent.config';
import { ISearchConfig } from '@/components/SearchTable/index.d';
import { DialogService } from '@/services/common/dialog.service';
import editprotocol from './dialogs/editprotocol.vue';
import { ConsentService } from '@/services/consent-service';
import { EditorStatus } from './index.interface';
import { cloneDeep, isArray } from 'lodash';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { UserUploadService } from '@/services/user-uploading';

@Component
export default class Consent extends Vue {
  public searchFormLoading: boolean = false;
  public loading: boolean = false;
  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }

  @Inject(DialogService) private dialog!: DialogService;
  @Inject(UserUploadService) private userUploadService!: UserUploadService;
  @Inject(ConsentService) private consentService!: ConsentService;

  private statusCode = StatusCode;
  private divisionlist: any[] = [];
  private form: any = {
    name: '',
    department: [],
    relevanceProtocol: '',
    createTime: '',
    effectiveTime: '',
    status: ''
  };
  private pagination: any = {
    pageSize: 10,
    total: 0,
    pageNum: 1
  };
  private searchConfig: ISearchConfig[] = [];
  private tableData: Dict<any> = [];
  private eventList: any[] = [];

  private async getList(val?: any, type?: string) {
    let form: any = { currentPage: 1, pageSize: 10 };
    if (type === 'pagination') {
      const { pageSize, pageNum } = val;
      form.currentPage = pageNum;
      form.pageSize = pageSize;
      form = Object.assign({}, { currentPage: pageNum, pageSize }, this.form);
    } else if (type === 'search') {
      form = Object.assign(form, val);
    }
    if (form.department) {
      form.department =
        form.department.length > 0 ? form.department.join(',') : '';
    }
    try {
      this.loading = true;
      const res = await this.consentService.getList(form);
      if (!res) {
        this.$notify.error({
          title: '错误',
          message: '获取列表失败，请您重试。'
        });
        return;
      }
      const { records, current, total, size } = res;
      this.pagination.pageSize = size;
      this.pagination.total = total;
      this.pagination.pageNum = current;
      if (records && isArray(records)) {
        this.tableData = records;
      }
    } finally {
      this.loading = false;
    }
  }

  private async editProtocol(row?: any) {
    const form = row
      ? { type: EditorStatus.edit, row, refresh: this.getList }
      : { type: EditorStatus.create, refresh: this.getList };
    const res = await this.dialog.open(
      this.i18n.admin_portal.privacy_protocol_detail,
      editprotocol,
      form
    );
    if (res.action === CloseStatus.confirm || res.action === CloseStatus.cancel) {
      this.getList();
    }
  }

  private async handleDeleteRow(id: string, name: string) {
    await this.$confirm(`确定删除${name}？`, '提示', {
      confirmButtonText: '确定删除',
      cancelButtonText: '放弃',
      type: 'warning'
    });
    this.loading = true;
    await this.consentService.delete(id);
    this.$notify({
      title: '成功',
      type: 'success',
      message: '删除成功！'
    });
    this.getList();
  }

  private async initData() {
    this.searchConfig = cloneDeep(searchConfig);
    try {
      this.searchFormLoading = true;
      this.searchConfig[2].options =
        (await this.consentService.getpublishedlist()) || [];
      const division: any = (await this.userUploadService.getDepartmentdivision()) || [];
      const divisionlist: any = [];
      division.forEach((item: any, index: number) => {
        divisionlist.push({ id: item.deleted, label: item.division_name, name: item.id, value: item.division_name });
      });
      this.divisionlist = divisionlist;
      this.searchConfig[1].options = divisionlist;
      await this.getList();
    } finally {
      this.searchFormLoading = false;
    }
  }

  private mounted(): void {
    this.initData();
  }

  private selectChange(val: Dict<any>): void {
    this.form = val;
  }
}
