import { Inject } from '@cds/common';
import { HttpService } from '@/services/common/http.service';

export class CategoryService {
  @Inject(HttpService) private http!: HttpService;

  public create(param: Dict<any>): Promise<any> {
    return this.http.post('/content/category', param);
  }




}
