export interface IEditorConf {
  path: string;
  plat_host?: string;
  appkey?: string;
  open_editor?: boolean;
  pageLoad?: boolean;
  style_url?: string;
  page_url?: string;
  uploadFormData?: {
    referer?: string;
  };
  style_width?: number;
  lang?: string;
  initialFrameHeight?: number;
  initialFrameWidth?: number;
  zIndex?: number;
  focus?: boolean;
  toolbars?: any[];
  autoFloatEnabled?: boolean;
  autoHeightEnabled?: boolean;
  scaleEnabled?: boolean;
  focusInEnd?: boolean;
  enableEndToolbar?: boolean;
  enableVideo?: boolean;
  disabled?: boolean;
}

export interface ISourceConf {
  scripts: string[];
  endScripts: string[];
  videoScripts: string[];
  css: string[];
}

export enum EditorCommand {
  inserthtml = 'inserthtml',
  bold = 'bold',
  italic = 'italic',
  subscript = 'subscript',
  supscript = 'supscript',
  forecolor = 'forecolor',
  backcolor = 'backcolor',
  undo = 'undo',
  redo = 'redo',
  source = 'source',
  selectall = 'selectall',
  cleardoc = 'cleardoc',
  drafts = 'drafts'
}
export interface IEditor {
  textarea?: HTMLTextAreaElement;
  iframe?: HTMLIFrameElement;
  destroy: () => void;
  addListener: (eventName: string, callback: () => void) => void;
  setContent: (content: string, isAdd?: boolean) => void;
  getContent: () => string;
  getContentTxt: () => string;
  getPlainTxt: () => string;
  hasContents: () => boolean;
  focus: () => void;
  blur: () => void;
  isFocus: () => boolean;
  setDisabled: () => void;
  setEnabled: () => void;
  setHide: () => void;
  setShow: () => void;
  selection: {
    getText: () => string
  };
  execCommand: (
    exec: EditorCommand,
    content?: string
  ) => void;
  registerCommand: (
    uiname: string,
    option: {
      execCommand: () => void
    }) => void;
  queryCommandState: (command: string) => number;
  commands: Dict<any>;
}

export interface IScriptElement extends HTMLScriptElement {
  loaded: boolean;
  readyState: string;
  onreadystatechange?: () => void;
}

export enum EditorEvent {
  ready = 'ready',
  selectionchange = 'selectionchange',
  contentchange = 'contentchange',
  keyup = 'keyup',
  keydown = 'keydown'
}

export enum MaterialType {
  insertcustomimagetext = 'insertcustomimagetext',
  insertcustomvideo = 'insertcustomvideo',
  insertcustomaudio = 'insertcustomaudio',
  insertcustomimage = 'insertcustomimage',
  insertcustomfile = 'insertcustomfile'
}

// export interface IInsertPluginItem {
//   id: string;
//   label: string;
// }
