











































































import { Component, Emit, Vue } from 'vue-property-decorator';
import { ArticleService } from '@/services/article-service';
import { Inject } from '@cds/common';
import { DialogService } from '@/services/common/dialog.service';
import EditNewImageText from './editNewImageText.vue';
import { uuid } from '@/services/utils';
import CreateNewImageText from './createNewImageText.vue';
import SortImageText from './sortImageText.vue';

@Component({})
export default class ImageTextMaterial extends Vue {
  public articleList: any[] = [];
  @Inject(ArticleService) private articleService!: ArticleService;
  @Inject(DialogService) private dialog!: DialogService;
  public async CreateNewImageText(): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '新建图文',
      CreateNewImageText,
      { key },
      { key, width: window.innerWidth * 0.85 }
    );
  }

  public async onEdit(data: any): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '编辑图文',
      EditNewImageText,
      { key, ...data },
      { key, width: window.innerWidth * 0.85 }
    );
  }

  public async SortImageText(): Promise<void> {
    const key = uuid();
    const res = await this.dialog.open(
      '图文排序',
      SortImageText,
      { key },
      { key, width: window.innerWidth * 0.85 }
    );
  }
  public mounted(): void {
    this.onQuery();
  }

  @Emit('select')
  public selectHandler(data: any): void {
    return data;
  }

  private async onQuery(): Promise<void> {
    const list = await this.articleService.getArticleList();
    if (list) {
      this.articleList = list;
    }
  }

  private async doDelete(param: any): Promise<void> {
    const result = await this.articleService.delete(param);
    let code: any = result.code;
    if (code === 200) {
      this.$message({
        message: 'del success',
        type: 'success'
      });
      this.onQuery();
    }
  }
}
