







import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import {
  EditorEvent,
  IEditor,
  IEditorConf,
  IScriptElement,
  ISourceConf
} from './editor.interface';
import {
  defaultEditorConfig,
  EditorSourceConfig
  // getInsertPluginList
} from './config';
import { assign, forEach } from 'lodash';
import { uuid } from '@/services/utils';
import { I18nService } from '@cds/i18n';
import { Inject } from '@cds/common';
import MaterialSelector from '../material-selector/material-selector.vue';
import { DialogService } from '@/services/common/dialog.service';
import { MaterialPluginService } from './material-plugin.service';

declare global {
  interface Window {
    UE: {
      getEditor: (
        id: string,
        option: IEditorConf & { UEDITOR_HOME_URL: string }
      ) => IEditor;
      I18N: Dict<any>;
      registerUI: (
        name: string,
        callback: (editor: any, uid: string) => any,
        index?: number[],
        editorId?: string
      ) => void;
      ui: Dict<any>;
      plugins: Dict<any>;
    };
    clean_135helper: () => void;
    current_editor: IEditor;
  }
}

@Component
export default class UEditor extends Vue {
  @Prop({
    type: String
  })
  public id!: string;

  @Prop({
    type: Object,
    default: () => defaultEditorConfig
  })
  public config!: IEditorConf;

  @Prop({
    type: String,
    default: ''
  })
  public value!: string;
  public uid: string = '';
  public sourceConfig: ISourceConf = EditorSourceConfig;
  public instance!: IEditor;
  private resConfig: IEditorConf = defaultEditorConfig;
  @Inject(I18nService) private i18nSvc!: I18nService;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(MaterialPluginService) private materialPlugin!: MaterialPluginService;
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  @Watch('value')
  public setValue(val: string): void {
    if (this.instance && val !== this.instance.getContent()) {
      this.instance.setContent(val);
    }
  }
  @Watch('config', {
    deep: true,
    immediate: true
  })
  public setConfig(val: IEditorConf): void {
    if (this.instance) {
      if (val.disabled) {
        this.instance.setDisabled();
      } else {
        this.instance.setEnabled();
      }
    }
  }
  public async created(): Promise<void> {
    this.resConfig = assign(defaultEditorConfig, this.config);
    this.uid = this.id || uuid();
    if (!window.UE) {
      await this.loadScript();
      forEach(this.sourceConfig.css, (css) => this.injectCss(css));

    }

    this.initEditor();
  }
  public beforeDestroy(): void {
    if (this.instance && this.instance.iframe && this.instance.destroy) {
      if (this.instance.textarea) {
        this.instance.textarea.remove();
      }
      this.instance.destroy();
    }
  }

  public injectScript(script: string): Promise<any> {
    let scriptTag = document.getElementById(script) as IScriptElement;
    if (!scriptTag) {
      scriptTag = document.createElement('script') as IScriptElement;
      scriptTag.setAttribute('type', 'text/javascript');
      scriptTag.setAttribute('src', this.resConfig.path + script);
      scriptTag.setAttribute('id', script);
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(scriptTag);
    }
    if (scriptTag.loaded || scriptTag.readyState === 'complete') {
      return Promise.resolve(script);
    }
    return new Promise((resolve) => {
      if (scriptTag.readyState) {
        // IE
        scriptTag.onreadystatechange = () => {
          if (
            scriptTag.readyState === 'loaded' ||
            scriptTag.readyState === 'complete'
          ) {
            delete scriptTag.onreadystatechange;
            resolve(script);
          }
        };
      } else {
        scriptTag.onload = () => {
          resolve(script);
        };
      }
    });
  }

  public injectCss(css: string): void {
    let cssTag = document.getElementById(css);
    if (!cssTag) {
      cssTag = document.createElement('link');
      cssTag.setAttribute('type', 'text/css');
      cssTag.setAttribute('href', this.resConfig.path + css);
      cssTag.setAttribute('id', css);
      cssTag.setAttribute('ref', 'stylesheet');
      const head = document.getElementsByTagName('head')[0];
      head.appendChild(cssTag);
    }
  }

  public async initEditor(): Promise<void> {
    if (!this.instance) {
      await this.$nextTick();
      if (!this.resConfig.initialFrameHeight) {
        const clientHeight = (this.$el.parentElement as HTMLElement)
          .clientHeight;
        this.resConfig.initialFrameHeight =
          clientHeight < 200 ? 200 : clientHeight;
        const clientWidth = (this.$el.parentElement as HTMLElement).clientWidth;
        this.resConfig.initialFrameWidth =
          clientWidth < 300 ? 300 : clientWidth;
      }
      this.instance = window.UE.getEditor(this.uid, {
        UEDITOR_HOME_URL: this.resConfig.path,
        // toolbars: [map(getInsertPluginList(this.i18n), (e) => e)],
        ...this.resConfig,
        lang: this.i18nSvc.currentLocaleId
      });
      this.instance.addListener(
        EditorEvent.ready,
        this.readyHandler.bind(this)
      );
      this.instance.addListener(EditorEvent.contentchange, () =>
        this.inputContent(this.instance.getContent())
      );
    }
  }

  @Emit('ready')
  public readHandler(editor: IEditor) {
    return editor;
  }

  @Emit('input')
  private inputContent(value: string) {
    return value;
  }

  // public openDialog(): void {
  //   this.dialog.open('素材列表', MaterialSelector, void 0, {
  //     height: window.innerHeight * 0.8
  //   });
  // }

  private async loadScript(): Promise<void> {
    await Promise.all(this.sourceConfig.scripts.map(this.injectScript));
    this.materialPlugin.registry(MaterialSelector);
    await this.injectScript(
      `/lang/${this.i18nSvc.currentLocaleId}/${this.i18nSvc.currentLocaleId}.js`
    );
    if (this.resConfig.enableEndToolbar) {
      await Promise.all(this.sourceConfig.endScripts.map(this.injectScript));
    }
    if (this.resConfig.enableVideo) {
      await Promise.all(this.sourceConfig.videoScripts.map(this.injectScript));
    }
  }

  private readyHandler(): void {
    this.instance.setContent(this.value);
    this.readHandler(this.instance);
    if (!window.current_editor) {
      window.current_editor = this.instance;
    }
    if (this.resConfig.disabled) {
      this.instance.setDisabled();
    }
  }
}
