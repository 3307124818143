
import ImageTextMaterial from './material-items/ImageTextMaterial.vue';
import ImageMaterial from './material-items/ImageMaterial.vue';
import VideoMaterial from './material-items/videoMaterial.vue';
import VoiceMaterial from './material-items/voiceMaterial.vue';
import FileMaterial from './material-items/fileMaterial.vue';
import { MaterialType } from '@/components/rich-editor/editor.interface';

export const MaterialCategoryList = [{
  id: MaterialType.insertcustomimagetext,
  view: ImageTextMaterial,
  label: '图文素材'
}, {
  id: MaterialType.insertcustomvideo,
  view: ImageMaterial,
  label: '视频素材'
}, {
  id: MaterialType.insertcustomaudio,
  view: VideoMaterial,
  label: '语音素材'
}, {
  id: MaterialType.insertcustomimage,
  view: VoiceMaterial,
  label: '图片素材'
}, {
  id: MaterialType.insertcustomfile,
  view: FileMaterial,
  label: '文件素材'
}];
