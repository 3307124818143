






























































































































































































import { Inject } from '@cds/common';
import { I18nService } from '@cds/i18n';
import { Component, Ref, Vue } from 'vue-property-decorator';
import UEditor from '../../../components/rich-editor/rich-editor.vue';
import { DialogService } from '@/services/common/dialog.service';
import { ApiService } from '@/services/api-service';
import { ChannelService } from '@/services/channel-service';
import { ElTable } from 'element-ui/types/table';
import { ElForm } from 'element-ui/types/form';
import { CloseStatus } from '@/services/common/models/dialog.interface';
import { DefaultFormData, getRules, DivisionOption } from '../config';
import { assign } from 'lodash';
import { channellist, channelobj } from './index.config';
@Component({
  components: {
    UEditor
  }
})
export default class AddChannelDialog extends Vue {
  // 表单验证
  public rules: any = getRules(this.i18n);
  public data!: any;
  public apiList: any[] = [];
  @Ref('form') private form!: ElForm;
  @Ref('multipleTable')
  private multipleTable!: ElTable;
  private formData = JSON.parse(JSON.stringify(DefaultFormData)); // 深拷贝
  private channellist = channellist;
  // private channelobje: any = channelobje;
  // division
  private divisionOption: any[] = DivisionOption;
  @Inject(DialogService) private dialog!: DialogService;
  @Inject(ApiService) private apiService!: ApiService;
  @Inject(ChannelService) private channelService!: ChannelService;
  // i18n
  @Inject(I18nService) private i18nSvc!: I18nService;

  get selectCount() {
    return this.formData.apiIds ? this.formData.apiIds.length : 0;
  }
  get i18n(): Dict<any> {
    return this.i18nSvc.i18nData;
  }
  public async created(): Promise<void> {
    this.apiList = await this.apiService.getList();
  }
  public closeAddModal(): void {
    this.clearForm();
    this.dialog.close();
  }
  private onSave(): void {
    this.form.validate(this.formValidHandler.bind(this));
  }
  private handlethirdparty(val: string) {
    this.formData.thirdPartyTitle = channelobj[val].title;
    this.formData.thirdPartyDescription = channelobj[val].content;
  }
  private async formValidHandler(valid: any): Promise<void | boolean> {
    if (valid) {
      // 获取divisionName
      if (this.formData.divisionCode) {
        let divisionCodeArr: any[] = [];
        divisionCodeArr = this.formData.divisionCode;
        let divisionTmp: any[] = [];
        divisionCodeArr.forEach((item: any, index: any) => {
          let division: any = {};
          let obj: any = {};
          obj = this.divisionOption.find((item1) => {
            // 这里的Data就是上面遍历的数据源
            return item1.value === item; // 筛选出匹配数据
          });
          if (obj) {
            division.divisionCode = obj.value;
            division.divisionName = obj.label;
            // divisionNameArr[index] = obj.label;
            divisionTmp[index] = division;
          }
        });
        this.formData.divisionList = divisionTmp;
      }
      const res = assign({}, this.formData);
      delete res.divisionCode;
      await this.channelService.create(res);
      this.clearForm();
      this.dialog.close(CloseStatus.confirm);
      this.$message({
        message: this.i18n.message.save_success,
        type: 'success'
      });
    } else {
      this.$message.error('请正确填写表单');
      return false;
    }
  }
  private clearForm() {
    this.formData = {
      id: '',
      channelName: '',
      channelAgentId: '',
      channelSecret: '',
      agentId: '',
      secret: '',
      divisionCode: [],
      multipleSelection: [], // 表格多选
      apiIds: [],
      actionType: '',
      mfa:'',
      mpAppid:'',
      mpHome:'',
      divisionList: [] as any[]
    };
    this.form.resetFields();
  }
  private async onCreateSecret(): Promise<void> {
    const h = this.$createElement;
    await this.$msgbox({
      title: this.i18n.channel_mgmt.regenerate,
      message: h('p', undefined, [
        h('span', undefined, this.i18n.channel_mgmt.recreate_info)
      ]),
      showCancelButton: true,
      cancelButtonText: this.i18n.channel_mgmt.cancel,
      confirmButtonText: this.i18n.channel_mgmt.confirm,
      confirmButtonClass: 'btn-color-blue',
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true;
          instance.confirmButtonText = this.i18n.channel_mgmt.doing;
          this.doCreateSecret();
          setTimeout(() => {
            done();
            setTimeout(() => {
              instance.confirmButtonLoading = false;
            }, 300);
          }, 3000);
        } else {
          done();
        }
      }
    });
  }
  private async doCreateSecret(): Promise<void> {
    const result = await this.channelService.getSecret();
    this.formData.secret = result;
  }
  // 表格全选功能
  private handleSelectionChange(val: any) {
    // console.log('______________', val);
    const arrayIds: any = [];
    // let arrayNames: any[] = [];
    val.forEach((item: any, index: number) => {
      arrayIds[index] = item.id;
    });
    this.formData.apiIds = arrayIds;
    this.formData.multipleSelection = val;
  }
  private change(): void {
    this.$forceUpdate();
  }
}
